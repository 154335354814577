import { forwardRef, HTMLAttributes, CSSProperties, useContext, useState, useEffect } from 'react'
import { moduleComponents } from './utils'
import { DashboardContext } from './AnalyticsDashboard'
import type { Location, ModuleItem, TimeRange } from './types'
import ModuleWrapperComponent from './ModuleWrapperComponent'

type ItemProps = HTMLAttributes<HTMLDivElement> & {
  item?: ModuleItem
  withOpacity?: boolean
  isDragging?: boolean
  isOverlay?: boolean
  draghandleProps?: any
}

function renderChart({
  item,
  timeRange,
  selectedLocation,
  isOverlay,
  isDragging,
  draghandleProps,
  timeRangeType,
  handleRemoveItem,
}: {
  item: ModuleItem
  timeRange: TimeRange
  selectedLocation: Location
  isOverlay?: boolean
  isDragging?: boolean
  draghandleProps?: any
  timeRangeType?: string
  handleRemoveItem: (item: ModuleItem) => void
}): JSX.Element | null {
  if (item?.module && moduleComponents[item?.module]) {
    const ModuleComponent = moduleComponents[item?.module]?.component
    const { title, showTimeFrame, button, Icon } = moduleComponents[item?.module]
    return ModuleComponent ? (
      <ModuleWrapperComponent
        timeRange={timeRange}
        item={item}
        isDragging={isDragging}
        draghandleProps={draghandleProps}
        title={title}
        showTimeFrame={showTimeFrame}
        button={button}
        Icon={Icon}
      >
        <ModuleComponent
          isOverlay={isOverlay}
          selectedLocation={selectedLocation}
          timeRange={timeRange}
          item={item}
          timeRangeType={timeRangeType}
        />
      </ModuleWrapperComponent>
    ) : null
  } else {
    handleRemoveItem(item)
    return null
  }
}

// Uses forwardRef to reference the DOM element
const Item = forwardRef<HTMLDivElement, ItemProps>(
  (
    { id, item, withOpacity, isDragging, isOverlay = false, draghandleProps, style, ...props },
    ref
  ) => {
    const {
      timeRange,
      location: selectedLocation,
      editModeEnabled,
      handleRemoveItem,
    } = useContext(DashboardContext)

    const [timeRangeType, setTimeRangeType] = useState<string>('')

    useEffect(() => {
      setTimeRangeType(timeRange?.type)
    }, [timeRange])

    const inlineStyles: CSSProperties = {
      opacity: withOpacity ? '0.5' : '1',
      width: '100%',
      borderRadius: '10px',
      cursor: isOverlay ? (isDragging ? 'grabbing' : 'grab') : null,
      backgroundColor: '#fff',
      minHeight: '20vw',
      height: editModeEnabled ? '25vw' : 'auto',
      boxShadow: isDragging
        ? 'rgb(63 63 68 / 5%) 0px 2px 0px 2px, rgb(34 33 81 / 15%) 0px 2px 3px 2px'
        : 'rgb(63 63 68 / 5%) 0px 0px 0px 1px, rgb(34 33 81 / 15%) 0px 1px 3px 0px',
      border: isDragging ? '2px solid #007bff' : 'none',
      ...style,
    }
    return (
      <div ref={ref} style={inlineStyles} {...props}>
        <div
          style={{
            padding: `1rem 2rem ${editModeEnabled ? '1rem' : '0.5rem'} 1rem`,
            minHeight: 'inherit',
            height: '100%',
          }}
        >
          {renderChart({
            item,
            timeRange,
            selectedLocation,
            isOverlay,
            isDragging,
            draghandleProps,
            timeRangeType,
            handleRemoveItem,
          })}
        </div>
      </div>
    )
  }
)

export default Item
