import { useRef, useContext } from 'react'
import { Toast } from 'primereact/toast'
import { moduleComponents } from './utils'
import { getModuleItemsBasedOnLevel, getModuleComponentFromName } from './utils'
import { maxModules } from './constants'
import { DashboardContext } from './AnalyticsDashboard'
import type { DashboardContextType, Level, ModuleItem } from './types'
import showToast from '../shared/ShowToast'
import { Dropdown } from 'primereact/dropdown'

type ModulePickerProps = {
  items: ModuleItem[]
  hideSelect?: boolean
  isBlankModule?: boolean
}

const ModulePicker: React.FC<ModulePickerProps> = ({ items, isBlankModule }) => {
  const { handleAddItem, level } = useContext<DashboardContextType>(DashboardContext)
  const moduleItems = getModuleItemsBasedOnLevel(level)

  // Get the modules that are currently on the page, from items state
  const modulesOnPage: string[] = items.map((item: ModuleItem) => item?.module)

  // All the modules available
  const allModules: string[] = moduleItems.map((module) => module.module)

  // Get the modules that are not currently on the page, which are used in the "Add Module" dropdown
  const modulesNotOnPage: string[] = allModules.filter((module) => !modulesOnPage.includes(module))

  const modulesWithTitles: { module: string; title: string }[] = modulesNotOnPage.map((module) => {
    return { module: module, title: moduleComponents[module].title }
  })

  function handleModuleClick(event: { value: string }): void {
    if (items.length >= maxModules) {
      handleNotificationEvent()
      return
    } else {
      // use setItems to add the module to the items state
      moduleItems.forEach((item: ModuleItem) => {
        if (item?.module === event.value) {
          if (isBlankModule) {
            handleAddItem(item, 'end')
          } else if (!isBlankModule) {
            handleAddItem(item, 'start')
          }
        }
      })
    }
  }

  const notification = useRef(null)

  const handleNotificationEvent = () => {
    showToast(
      notification,
      'error',
      'Too many modules',
      `You can only have ${maxModules} modules on the page at once`
    )
  }

  // Group the modules by their group
  const groupedModules = []

  modulesWithTitles.forEach((module) => {
    const group = getModuleComponentFromName(module.module).group
    const existingGroup = groupedModules.find((groupedModule) => groupedModule.label === group)

    const newItem = { label: module.title, value: module.module }

    if (existingGroup) {
      existingGroup.items.push(newItem)
    } else {
      groupedModules.push({ label: group, items: [newItem] })
    }
  })

  // If website level, put general group at the end as there's only 1 module in it
  if (level === 'Website') {
    const generalGroup = groupedModules.find((groupedModule) => groupedModule.label === 'General');
    if (generalGroup) {
      groupedModules.splice(groupedModules.indexOf(generalGroup), 1);
      groupedModules.push(generalGroup);
    }
  }

  return (
    <>
      <Toast ref={notification} />
      <Dropdown
        placeholder="Add Module"
        onChange={handleModuleClick}
        options={groupedModules}
        optionGroupLabel="label"
        optionGroupChildren="items"
        optionLabel="label"
      ></Dropdown>
    </>
  )
}

export default ModulePicker
