import { useContext, useEffect, useMemo, useRef, useState } from 'react'
import { DashboardContextType, Level, TimeRange, Location } from './types'
import { DashboardContext } from './AnalyticsDashboard'
import { fetchModuleData } from './utils'

export const useViewportData = (
  module: string,
  timeRange: TimeRange,
  selectedLocation: Location
): {
  data: any
  loading: boolean
  viewportRef: React.RefObject<HTMLDivElement>
} => {
  const { level, selectedTab } = useContext<DashboardContextType>(DashboardContext)
  const [data, setData] = useState<any>(null)
  const [loading, setLoading] = useState<boolean>(false)
  const [viewed, setViewed] = useState<boolean>(false)
  const viewportRef = useRef<HTMLDivElement>(null)
  const isInViewport = useOnScreen(viewportRef)

  const loadData = (params: {
    module: string
    timeRange: TimeRange
    selectedLocation: Location
    level: Level
  }) => {
    setLoading(true)
    fetchModuleData(params)
      .then((data) => setData(data))
      .catch((error) => console.error('Error fetching data:', error))
      .finally(() => {
        setLoading(false)
        setViewed(true)
      })
  }

  // Load the data when the element is in the viewport and hasn't been viewed yet.
  useEffect(() => {
    if (isInViewport && !viewed) {
      loadData({ module, timeRange, selectedLocation, level })
    }
  }, [isInViewport, viewed, module, timeRange, selectedLocation, level, selectedTab])

  // Reload data when timeRange, selectedLocation or level changes,
  // regardless of whether the component has been viewed or not.
  useEffect(() => {
    if (viewed) {
      loadData({ module, timeRange, selectedLocation, level })
    }
  }, [module, timeRange, selectedLocation, level, selectedTab])

  return { data, loading, viewportRef }
}

export function useOnScreen(ref: React.RefObject<HTMLDivElement>): boolean {
  const [isIntersecting, setIntersecting] = useState<boolean>(false)

  useEffect(() => {
    const observer = new IntersectionObserver(([entry]) =>
      setIntersecting(entry.isIntersecting)
    )

    if (ref.current) {
      observer.observe(ref.current)
    }

    return () => {
      if (ref.current) {
        observer.unobserve(ref.current)
      }
      observer.disconnect()
    }
  }, [ref])

  return isIntersecting
}