import LoadingBoxes from '../../entries/LoadingBoxes'
import ChartComponent from '../Charts/ChartComponent'
import type { ModuleProps, TimeRange } from '../types'
import { useViewportData } from '../hooks'
import { getModuleComponentFromName } from '../utils'

const PaymentsFooter: React.FC<{ data: any; timeRange: TimeRange }> = ({ data, timeRange }) => {
  const timeframe = (timeRange?.timeframe || 'Last 30 Days').toLowerCase()
  return (
    <>
      {data?.number_of_payments > 0 ? (
        <ul className="mt-4">
          <li>
            {data.number_of_payments} payments {timeframe}
          </li>
          <li>
            {data.total_amount} total in payments {timeframe}
          </li>
        </ul>
      ) : null}
    </>
  )
}

const GenericModuleComponent: React.FC<ModuleProps> = ({
  timeRange,
  item,
  selectedLocation,
  isOverlay = false,
}) => {
  const { data, loading, viewportRef } = useViewportData(item?.module, timeRange, selectedLocation)
  const moduleConfig = getModuleComponentFromName(item?.module)

  return (
    <div className="h-100" style={{ minHeight: 'inherit' }} ref={viewportRef}>
      {!loading && data?.[item?.module] ? (
        <ChartComponent
          chartData={data?.[item?.module] || []}
          isOverlay={isOverlay}
          module={item?.module}
        />
      ) : (
        <LoadingBoxes />
      )}
      {moduleConfig?.showSpamLeadsDisclaimer ? (
        <small className="text-secondary">*Excludes leads marked as "spam"</small>
      ) : null}
      <br />
      {moduleConfig?.showExcludedCategoriesDisclaimer ? (
        <small className="text-secondary">
          **Excluded categories: Trade in, Sell My Car, Vehicle Reservation, Phone Call & General
          Enquiry.
        </small>
      ) : null}
      {moduleConfig?.title === 'Payments' ? (
        <PaymentsFooter data={data} timeRange={timeRange} />
      ) : null}
    </div>
  )
}

export default GenericModuleComponent
