import { CSSProperties, useContext } from 'react'
import { DashboardContext } from './AnalyticsDashboard'
import DragHandle from './DragHandle'
import ModuleCloser from './ModuleCloser'
import type { ChartType, ModuleComponentConfig, ModuleItem, TimeRange } from './types'
import { Dropdown, DropdownChangeEvent } from 'primereact/dropdown'
import { getModuleComponentFromName } from './utils'
import { Provider, Root, Trigger, Content } from '@radix-ui/react-tooltip'

type ModuleWrapperComponentProps = {
  title: string
  item: ModuleItem
  timeRange?: TimeRange
  children: React.ReactNode
  isDragging?: boolean
  draghandleProps?: any
  showTimeFrame?: boolean
  button?: ModuleComponentConfig['button']
  Icon?: React.FC
}

export const TooltipContentStyles: CSSProperties = {
  borderRadius: '4px',
  padding: '10px 15px',
  fontSize: '15px',
  lineHeight: '1.2',
  color: 'white',
  backgroundColor: 'rgb(0, 66, 122,0.9)',
  boxShadow: 'hsl(206 22% 7% / 35%) 0px 10px 38px -10px, hsl(206 22% 7% / 20%) 0px 10px 20px -15px',
  userSelect: 'none',
  animationDuration: '400ms',
  animationTimingFunction: 'cubic-bezier(0.16, 1, 0.3, 1)',
  willChange: 'transform, opacity',
  zIndex: '99',
  maxWidth: '400px',
}

const ModuleWrapperComponent: React.FC<ModuleWrapperComponentProps> = ({
  title,
  item,
  timeRange,
  children,
  isDragging,
  draghandleProps,
  showTimeFrame = true,
  button,
  Icon,
}) => {
  const { editModeEnabled, handleSetChartTypes, chartTypes, selectedTab } =
    useContext(DashboardContext)

  const handleChartTypeChange = (event: DropdownChangeEvent): void => {
    handleSetChartTypes(item.module, event.target.value)
  }

  const moduleComponent = getModuleComponentFromName(item.module)

  // Exclude certain chart types that don't make sense for the data
  const dropdownOptions = ['Pie', 'Bar', 'Line'].filter((chart) => {
    return !moduleComponent?.excludedChartTypes?.includes(chart as ChartType)
  })

  const chartTypeExists = chartTypes[selectedTab] ? item?.module in chartTypes[selectedTab] : false
  const defaultChartType = moduleComponent?.defaultChartType || 'Bar'
  const chartType = chartTypeExists ? chartTypes[selectedTab][item.module] : defaultChartType

  return (
    <>
      <div className="d-flex align-items-center">
        {Icon && <Icon />}
        <div className="mr-auto d-flex align-items-center">
          <h4 className="m-0" style={{ fontSize: editModeEnabled ? '14px' : '21px' }}>
            {title}
          </h4>
          <Provider delayDuration={50}>
            <Root>
              <Trigger asChild>
                <i className="fas fa-info-circle ml-2" style={{ cursor: 'pointer' }}></i>
              </Trigger>
              <Content style={TooltipContentStyles} sideOffset={5}>
                {moduleComponent?.infoDescription}
              </Content>
            </Root>
          </Provider>
        </div>
        {editModeEnabled && !moduleComponent?.isCustomChart && (
          <div className="mx-auto">
            <span className="mr-2">Chart type:</span>
            <Dropdown
              onChange={handleChartTypeChange}
              value={chartType}
              options={dropdownOptions}
            ></Dropdown>
          </div>
        )}
        {showTimeFrame && !editModeEnabled && (
          <h4>
            <span className="small text-secondary m-0">
              {timeRange?.timeframe || 'Last 30 Days'}
            </span>
          </h4>
        )}
        {button && !editModeEnabled && (
          <span>
            <a href={button.link} className="btn btn-secondary btn-sm">
              {button.text}
            </a>
          </span>
        )}
        {editModeEnabled && (
          <>
            <DragHandle isDragging={isDragging} draghandleProps={draghandleProps} />
            <ModuleCloser item={item} />
          </>
        )}
      </div>
      <div
        style={
          editModeEnabled
            ? { overflow: 'auto', height: 'calc(100% - 25px)' }
            : { height: '100%', minHeight: 'inherit' }
        }
      >
        {children}
      </div>
    </>
  )
}

export default ModuleWrapperComponent
