import {
  DndContext,
  DragOverEvent,
  DragOverlay,
  DragStartEvent,
  MouseSensor,
  TouchSensor,
  closestCenter,
  useSensor,
  useSensors,
} from '@dnd-kit/core'
import { SortableContext, arrayMove } from '@dnd-kit/sortable'
import { useCallback, useState } from 'react'
import Item from './Item'
import SortableItem from './SortableItem'
import type { ModuleItem, SetState } from './types'
import LoadingBoxes from '../entries/LoadingBoxes'
import BlankModule from './BlankModule'

const DragAndDrop: React.FC<{
  items: ModuleItem[]
  setItems: SetState<ModuleItem[]>
  editModeEnabled: boolean
  handleDrop: (updatedItems: ModuleItem[]) => void
}> = ({ items, setItems, editModeEnabled, handleDrop }) => {
  const [activeId, setActiveId] = useState<string | null>(null)
  const sensors = useSensors(useSensor(MouseSensor), useSensor(TouchSensor))

  // Sets the activeId state when a drag starts
  const handleDragStart = useCallback((event: DragStartEvent) => {
    setActiveId(event.active.id as string) // Should always be a string // item.module
  }, [])

  // Updates the items state when a drag is over another item
  const handleDragOver = useCallback((event: DragOverEvent) => {
    const { active, over } = event

    // If the active and over ids are different, update the items state
    if (over && active.id !== over?.id) {
      setItems((items) => {
        const oldIndex = items.findIndex((item) => item.module === active.id)
        const newIndex = items.findIndex((item) => item.module === over?.id)

        const updatedItems = arrayMove(items, oldIndex, newIndex)

        // Sync the tabLayouts state with the updated items
        handleDrop(updatedItems)

        return updatedItems
      })
    }
  }, [handleDrop])

  const handleDragEnd = useCallback(() => {
    // Always reset the activeId state
    setActiveId(null)
  }, [])

  // Set activeId to null if the drag is cancelled
  const handleDragCancel = useCallback(() => {
    setActiveId(null)
  }, [])

  // Sets the active item for the drag overlay
  const activeItem = items.find((item) => item.module === activeId)

  return (
    <>
      <div className="row">
        <DndContext
          onDragStart={handleDragStart}
          onDragEnd={handleDragEnd}
          onDragOver={handleDragOver}
          onDragCancel={handleDragCancel}
          collisionDetection={closestCenter}
          sensors={sensors}
        >
          {/* The below map needs to happen to use the module name as the unique identifier */}
          <SortableContext items={items.map(item => item.module)} strategy={() => null}>
            {!editModeEnabled ? (
              <>
                <div className="p-0 col-12 col-xl-6">
                  {items.map((item, index) => {
                    if (index % 2 !== 0) return null
                    return (
                      <div className="p-3" key={index}>
                        <SortableItem id={item.module} item={item} />
                      </div>
                    )
                  })}
                </div>
                <div className="p-0 col-12 col-xl-6">
                  {items.map((item, index) => {
                    if (index % 2 === 0) return null
                    return (
                      <div className="p-3" key={index}>
                        <SortableItem id={item.module} item={item} />
                      </div>
                    )
                  })}
                </div>
              </>
            ) : (
              items.map((item, index) => (
                <div className="col-12 col-xl-6 p-3" key={index}>
                  <SortableItem id={item.module} item={item} />
                </div>
              ))
            )}
            <div className="p-3 col-12 col-xl-6">
              <BlankModule editModeEnabled={editModeEnabled} items={items} />
            </div>
          </SortableContext>
          <DragOverlay adjustScale style={{ transformOrigin: '0 0 ' }}>
            {activeId ? (
              <>
                <Item id={activeId} item={activeItem} isDragging isOverlay />
              </>
            ) : (
              <LoadingBoxes />
            )}
          </DragOverlay>
        </DndContext>
      </div>
    </>
  )
}

export default DragAndDrop
