import { createContext, useEffect, useState } from 'react'
import TimeRangePicker from '../entries/TimeRange'
import * as Routes from '../../routes'

import DragAndDrop from './DragAndDrop'
import EditMode from './EditMode'
import ModulePicker from './ModulePicker'
import SelectLocations from './SelectLocations'
import type {
  ChartType,
  ChartTypesByTab,
  DashboardContextType,
  Level,
  Location,
  ModuleItem,
  TimeRange,
} from './types'
import {
  fetchModuleLayoutByTab,
  getModuleItemsBasedOnLevel,
  getTabTitles,
  setModuleLayouts,
  getModuleComponentFromName,
  getAnalyticsBlock,
  getNameBasedOnLevel,
  getDealership,
  getWebsite,
} from './utils'
import { maxModules } from './constants'
import Tabs from './Tabs'

export const DashboardContext = createContext<DashboardContextType>({
  timeRange: undefined,
  location: undefined,
  editModeEnabled: false,
  chartTypes: [],
  selectedTab: 0,
  level: 'Dealership',
  handleAddItem: function (item: ModuleItem, position: string): void {
    throw new Error('Function not implemented.')
  },
  handleRemoveItem: function (item: ModuleItem): void {
    throw new Error('Function not implemented.')
  },
  handleSetChartTypes: function (): void {
    throw new Error('Function not implemented.')
  },
})

const AnalyticsDashboard: React.FC<{ canView: boolean; level: Level }> = ({ canView, level }) => {
  const name = getNameBasedOnLevel(level)
  const moduleItems = getModuleItemsBasedOnLevel(level)
  const dealership = getDealership()
  const [timeRange, setTimeRange] = useState<TimeRange>({
    start: 0,
    end: 0,
    timeframe: '',
    type: '',
  })
  const [selectedLocation, setSelectedLocation] = useState<Location | null>(null)
  const [editModeEnabled, setEditModeEnabled] = useState<boolean>(false)
  const [selectedTab, setSelectedTab] = useState<number>(0)

  // Get the website data if the level is Website, for the GA Profile check
  const website = level === 'Website' ? getWebsite() : null
  const gaProfileExists = website?.gaProfileExists ?? false
  const hasCampaigns = website?.hasCampaigns ?? false

  // Set the tab layouts state based on the saved layouts, if there are no saved layouts for the first tab, use the default modules, otherwise do nothing for the other tabs
  const [tabLayouts, setTabLayouts] = useState<{ [key: number]: ModuleItem[] }>({
    0: fetchModuleLayoutByTab(0) ?? moduleItems.slice(0, maxModules),
    ...(fetchModuleLayoutByTab(1) && { 1: fetchModuleLayoutByTab(1) }),
    ...(fetchModuleLayoutByTab(2) && { 2: fetchModuleLayoutByTab(2) }),
    ...(fetchModuleLayoutByTab(3) && { 3: fetchModuleLayoutByTab(3) }),
    ...(fetchModuleLayoutByTab(4) && { 4: fetchModuleLayoutByTab(4) }),
  })
  const [chartTypes, setChartTypes] = useState<ChartTypesByTab[]>(() => {
    const initialChartTypes: ChartTypesByTab[] = []
    for (const tabId in tabLayouts) {
      initialChartTypes[tabId] = Object.fromEntries(
        tabLayouts[tabId].map((module) => [
          module.module,
          module.chartType ?? getModuleComponentFromName(module.module)?.defaultChartType ?? 'Bar',
        ])
      )
    }
    return initialChartTypes
  })
  const [tabTitles, setTabTitles] = useState<string[]>(getTabTitles())
  const [items, setItems] = useState<ModuleItem[]>(tabLayouts[selectedTab])

  const multipleTabs: boolean = Object.keys(tabLayouts).length > 1 && tabTitles.length > 1

  const handleTimeRangeChange = (dateRange: TimeRange): void => {
    setTimeRange(dateRange)
  }

  const handleLocationChange = (location: Location): void => {
    setSelectedLocation(location)
  }

  const toggleEditMode = (): void => {
    setEditModeEnabled(!editModeEnabled)
  }

  const addGAProfile = (): void => {
    const dealershipId = website?.dealershipId ?? dealership?.id
    window.open(Routes.fetch_access_token_dealership_ga_profiles_path(dealershipId), '_blank')
  }

  const handleAddItem = (item: ModuleItem, position: string): void => {
    // Add the item to the tab layout, as well as the current tabs items
    setTabLayouts((prevTabLayouts) => {
      const updatedItems =
        position === 'start'
          ? [item, ...prevTabLayouts[selectedTab]]
          : [...prevTabLayouts[selectedTab], item]

      setItems(updatedItems)
      return {
        ...prevTabLayouts,
        [selectedTab]: updatedItems,
      }
    })
  }

  const handleRemoveItem = (item: ModuleItem): void => {
    // Remove the item from the tab layout, as well as the current tabs items
    setTabLayouts((prevTabLayouts) => {
      const updatedItems = prevTabLayouts[selectedTab].filter((i) => i.module !== item.module)
      setItems(updatedItems)
      return {
        ...prevTabLayouts,
        [selectedTab]: updatedItems,
      }
    })
  }

  const handleDrop = (updatedItems: ModuleItem[]): void => {
    setTabLayouts((prevTabLayouts) => {
      const newLayouts = { ...prevTabLayouts, [selectedTab]: updatedItems }
      return newLayouts
    })
    setItems(updatedItems)
  }

  const handleSetPreviousItems = (): void => {
    const titles = getTabTitles()
    const layouts = {
      ...(fetchModuleLayoutByTab(0) && { 0: fetchModuleLayoutByTab(0) }),
      ...(fetchModuleLayoutByTab(1) && { 1: fetchModuleLayoutByTab(1) }),
      ...(fetchModuleLayoutByTab(2) && { 2: fetchModuleLayoutByTab(2) }),
      ...(fetchModuleLayoutByTab(3) && { 3: fetchModuleLayoutByTab(3) }),
      ...(fetchModuleLayoutByTab(4) && { 4: fetchModuleLayoutByTab(4) }),
    }

    setModuleLayouts(layouts, titles)
    setTabTitles(titles)

    setTabLayouts(() => {
      // Set the tab layouts, and set the selected tab if there is one, otherwise fall back to the first tab
      const newLayouts = layouts
      let selectedTabFound = false

      Object.keys(layouts)
        .reverse()
        .forEach((tabId) => {
          if (parseInt(tabId) === selectedTab) {
            handleTabChange(parseInt(tabId))
            selectedTabFound = true
          }
        })

      if (!selectedTabFound) {
        handleTabChange(0)
      }

      return newLayouts
    })

    const newChartTypes = Object.entries(layouts).map(([tab, modules]) =>
      Object.fromEntries(
        modules.map((module) => [
          module.module,
          module.chartType ?? getModuleComponentFromName(module.module)?.defaultChartType ?? 'Bar',
        ])
      )
    )

    setChartTypes(newChartTypes)
  }

  // TODO: Disabled temporarily, need to implement a way to set default modules for each tab
  const handleSetDefaultItems = (): void => {
    setItems(moduleItems.slice(0, maxModules))
    // setModuleLayoutByTab(modulesWithIds.slice(0, maxModules), 0)
  }

  const handleTabChange = (tabIndex: number): void => {
    setSelectedTab(tabIndex)
    setItems(tabLayouts[tabIndex])
  }

  const handleSetChartTypes = (module: string, newType: ChartType) => {
    setChartTypes((prevChartTypes) => {
      const updatedChartTypes = [...prevChartTypes]
      updatedChartTypes[selectedTab] = {
        ...updatedChartTypes[selectedTab],
        [module]: newType,
      }
      return updatedChartTypes
    })

    setTabLayouts((prevTabLayouts) => {
      const updatedItems = prevTabLayouts[selectedTab].map((item) => {
        if (item.module === module) {
          return { ...item, chartType: newType }
        }
        return item
      })

      setItems(updatedItems)
      return {
        ...prevTabLayouts,
        [selectedTab]: updatedItems,
      }
    })
  }

  useEffect(() => {
    if (tabLayouts && selectedTab in tabLayouts) {
      setItems(tabLayouts[selectedTab])
    }
  }, [tabLayouts, selectedTab])

  // If there is no data in the block, set the current items to the block
  useEffect(() => {
    const blockData = getAnalyticsBlock().data
    if (blockData && Object.keys(blockData).length === 0 && blockData.constructor === Object) {
      setModuleLayouts(tabLayouts, tabTitles)
    }
  }, [])

  const contextValue: DashboardContextType = {
    timeRange,
    location: selectedLocation,
    editModeEnabled,
    chartTypes,
    selectedTab,
    level,
    handleRemoveItem,
    handleAddItem,
    handleSetChartTypes,
  }

  // Conditional rendering variables
  const isManufacturer: boolean = level === 'Manufacturer'
  const showName: boolean = name !== undefined && !isManufacturer

  return (
    <DashboardContext.Provider value={contextValue}>
      {canView ? (
        <div className="p-4">
          <div className="row">
            {!showName ? <h3 className="col-6 p-0 pl-3">Analytics Dashboard</h3> : null}
            {showName ? <h4 className="col-6 pl-3">{name}</h4> : null}
            {editModeEnabled && isManufacturer ? (
              <div className="ml-auto">
                <ModulePicker items={items} />
              </div>
            ) : null}
            <div className="col-6 d-flex pl-0">
                <div className="ml-auto d-flex">
                  <SelectLocations handleLocationChange={handleLocationChange} /> &nbsp;
                  <TimeRangePicker handleTimeRangeChange={handleTimeRangeChange} />
                </div>
            </div>
          </div>
          <div className="d-flex align-items-center py-2">
            {!isManufacturer ? <h3 className="col-6 p-0">Analytics Dashboard</h3> : null}
            <div className={`col-${isManufacturer ? '12' : '6'} p-0 d-flex justify-content-end`}>
              <EditMode
                editModeEnabled={editModeEnabled}
                toggleEditMode={toggleEditMode}
                handleSetPreviousItems={handleSetPreviousItems}
                handleSetDefaultItems={handleSetDefaultItems}
                tabLayouts={tabLayouts}
                tabTitles={tabTitles}
                addGAProfile={addGAProfile}
                level={level}
                gaProfileExists={gaProfileExists}
                hasCampaigns={hasCampaigns}
              />
              {editModeEnabled && !isManufacturer ? <ModulePicker items={items} /> : null}
            </div>
          </div>
          {multipleTabs || editModeEnabled ? (
            <div className="d-flex align-items-center py-2">
              <Tabs
                editModeEnabled={editModeEnabled}
                tabTitles={tabTitles}
                setTabTitles={setTabTitles}
                handleTabChange={handleTabChange}
                selectedTab={selectedTab}
                setSelectedTab={setSelectedTab}
                setTabLayouts={setTabLayouts}
              />
            </div>
          ) : null}
          <DragAndDrop
            items={items}
            setItems={setItems}
            editModeEnabled={editModeEnabled}
            handleDrop={handleDrop}
          />
        </div>
      ) : null}
    </DashboardContext.Provider>
  )
}

export default AnalyticsDashboard
