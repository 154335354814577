export const Trophy: React.FC = () => (
  <i className="fa fa-trophy mr-1" style={{ color: '#ffc800', fontSize: '25px' }}></i>
)

export const Bin: React.FC = () => <i className="fa-regular fa-trash-can mt-1"></i>

export const Move: React.FC = () => (
  <i className="fa-regular fa-up-down-left-right" style={{ fontSize: '20px' }}></i>
)
export const Save: React.FC = () => <i className="ml-1 fa-regular fa-floppy-disk"></i>

export const Exit: React.FC = () => <i className="ml-1 fa-regular fa-circle-xmark"></i>

export const Reset: React.FC = () => <i className="ml-1 fa-solid fa-arrow-rotate-right"></i>

export const Plus: React.FC = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="46" height="46" viewBox="0 0 46 46" fill="none">
    <path
      d="M23 15.3333V30.6667M30.6667 23H15.3333M23 40.25C32.5269 40.25 40.25 32.5269 40.25 23C40.25 13.4731 32.5269 5.75 23 5.75C13.4731 5.75 5.75 13.4731 5.75 23C5.75 32.5269 13.4731 40.25 23 40.25Z"
      stroke="#5867FE"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
