import { DashboardContext } from './AnalyticsDashboard'
import { useContext } from 'react'
import type { DashboardContextType, ModuleItem } from './types'
import { Bin } from './Icons'

const ModuleCloser: React.FC<{ item: ModuleItem }> = ({ item }) => {
  const { handleRemoveItem } = useContext<DashboardContextType>(DashboardContext)
  const handleCloseModule = () => {
    if (item) {
      handleRemoveItem(item)
    }
  }
  return (
    <div
      onClick={handleCloseModule}
      style={{ cursor: 'pointer', display: 'flex', userSelect: 'none' }}
    >
      <Bin />
      <span className="ml-3" style={{ color: '#5f6269' }}>
        Delete
      </span>
    </div>
  )
}

export default ModuleCloser
