import { CSSProperties, Dispatch, SetStateAction, useEffect, useRef, useState } from 'react'
import { Dialog } from 'primereact/dialog'
import { ModuleItem } from './types'

const Tabs: React.FC<{
  editModeEnabled: boolean
  selectedTab: number
  tabTitles: string[]
  setTabTitles: Dispatch<SetStateAction<string[]>>
  handleTabChange: (tabIndex: number) => void
  setSelectedTab: Dispatch<SetStateAction<number>>
  setTabLayouts: Dispatch<SetStateAction<{ [key: number]: ModuleItem[] }>>
}> = ({
  editModeEnabled,
  selectedTab,
  tabTitles,
  setTabTitles,
  handleTabChange,
  setSelectedTab,
  setTabLayouts,
}) => {
  const [isEditing, setIsEditing] = useState<boolean>(false)
  const [isDialogVisible, setIsDialogVisible] = useState<boolean>(false)
  const inputRef = useRef<HTMLInputElement>(null)

  const handleTabNameChange = (newTabName: string): void => {
    setTabTitles((prevTabTitles) =>
      prevTabTitles.map((title, index) => (index === selectedTab ? newTabName : title))
    )
  }

  const handleDeleteTab = (tabIndex: number): void => {
    setIsDialogVisible(false)

    // Updat the tabLayout, and update the index's for proper deletion
    setTabLayouts((prevTabLayouts) => {
      const updatedTabLayouts: { [key: number]: ModuleItem[] } = {}
      Object.keys(prevTabLayouts).forEach((key) => {
        const originalIndex = parseInt(key, 10)
        if (originalIndex < tabIndex) {
          updatedTabLayouts[originalIndex] = prevTabLayouts[originalIndex]
        } else if (originalIndex > tabIndex) {
          updatedTabLayouts[originalIndex - 1] = prevTabLayouts[originalIndex]
        }
      })
      return updatedTabLayouts
    })
    setTabTitles((prevTabTitles) => prevTabTitles.filter((_, index) => index !== tabIndex))

    // When you delete a tab, make the first tab active, unless you delete the first tab
    if (tabIndex !== 0) {
      setTimeout(() => {
        handleTabChange(0)
      }, 0)
    } else {
      let newSelectedTab = selectedTab
      if (selectedTab === tabIndex) {
        newSelectedTab = 0
      } else if (selectedTab > tabIndex) {
        newSelectedTab -= 1
      }
      setTimeout(() => {
        setSelectedTab(newSelectedTab)
      }, 0)
    }
  }

  const handleAddTab = (): void => {
    setTabLayouts((prevTabLayouts) => ({ ...prevTabLayouts, [tabTitles.length]: [] }))
    setTabTitles((prevTabTitles) => [...prevTabTitles, 'New Tab'])
  }

  const tabStyles: CSSProperties = {
    width: '20%',
    height: '38px',
    position: 'relative',
  }

  const activeStyles: CSSProperties = {
    color: 'rgb(255, 255, 255)',
    backgroundColor: 'rgb(56, 124, 255)',
    borderColor: 'rgb(56, 124, 255)',
  }

  const inputStyles: CSSProperties = {
    width: '80%',
    height: '100%',
    border: 'none',
    backgroundColor: 'white',
    color: 'black',
    borderRadius: '0.25rem',
    textAlign: 'center',
    outline: 'none',
    padding: '0',
    margin: '0',
    fontSize: '1rem',
  }

  // Focus on the input when the edit icon is clicked
  useEffect(() => {
    if (isEditing && inputRef.current) {
      inputRef.current.focus()
    }
  }, [isEditing])

  return (
    <div className="d-flex w-100">
      {tabTitles.map((title, index) => (
        <button
          key={index}
          className={`btn btn-outline-primary mx-2`}
          style={{ ...tabStyles, ...(selectedTab === index ? activeStyles : {}) }}
          onClick={() => handleTabChange(index)}
        >
          <div className="d-flex w-100 justify-content-center align-items-center">
            {editModeEnabled && tabTitles.length > 1 && (
              <i
                className="fas fa-times-circle closeTab"
                onClick={() => {
                  handleTabChange(index) // Remember the selected tab for deletion
                  setIsDialogVisible(true)
                }}
              ></i>
            )}
            <Dialog
              header={`Are you sure you want to delete the ${tabTitles[selectedTab]} tab?`}
              visible={isDialogVisible}
              style={{ width: '50vw', textAlign: 'center' }}
              onHide={() => setIsDialogVisible(false)}
              dismissableMask
            >
              <i
                className="far fa-exclamation-triangle"
                style={{ color: '#fcc600', fontSize: '22px' }}
              ></i>
              <h5 className="py-1 ">
                This will remove the tab and all module preferences permanently.
              </h5>
              <div style={{ display: 'flex', justifyContent: 'space-evenly' }}>
                <button
                  className="btn btn-primary mt-2 "
                  onClick={() => setIsDialogVisible(false)}
                  style={{ width: '150px' }}
                >
                  Cancel
                </button>
                <button
                  className="btn btn-danger mt-2 "
                  onClick={() => handleDeleteTab(selectedTab)}
                  style={{ width: '150px' }}
                >
                  Delete Tab
                </button>
              </div>
            </Dialog>
            {isEditing && selectedTab === index ? (
              <input
                ref={inputRef}
                type="text"
                value={tabTitles[selectedTab]}
                onChange={(e) => handleTabNameChange(e.target.value)}
                onBlur={() => setIsEditing(false)}
                style={inputStyles}
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    setIsEditing(false)
                  }
                }}
              />
            ) : (
              <div>
                <h5 className="m-0 text-center flex-grow-1">{title}</h5>
              </div>
            )}
            {editModeEnabled && (
              <i
                className="fal fa-edit ml-auto editTab"
                onClick={() => setIsEditing(!isEditing)}
              ></i>
            )}
          </div>
        </button>
      ))}
      {tabTitles.length < 5 && editModeEnabled && (
        <button className="btn btn-success" style={{ width: '50px' }} onClick={handleAddTab}>
          +
        </button>
      )}
    </div>
  )
}

export default Tabs
