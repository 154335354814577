import * as Routes from '../../routes'
import { standardHeaders } from '../entries/utils'

import { Trophy } from './Icons'
import {
  CarStats,
  LeadAnalytics,
  LeaderBoard,
  LeadsByMake,
  TopPageViews,
  GenericModuleComponent,
} from './Modules/index'

import showToast from '../shared/ShowToast'
import { capitalize } from '../editor/common/Utils'

import type {
  AnalyticsBlock,
  ChartData,
  Dealership,
  Level,
  Location,
  Manufacturer,
  ModuleComponentConfig,
  ModuleComponents,
  ModuleItem,
  TimeRange,
  Website,
  NotificationType,
} from './types'
import { RefObject } from 'react'
import { Toast } from 'primereact/toast'
import { rootUrl } from './constants'
import EngagementByCampaign from './Modules/EngagementByCampaign'

export const ga4Modules: string[] = [
  'google_analytics',
  'sessions',
  'sessions_by_medium',
  'total_users',
  'traffic_by_device',
  'new_users',
  'views',
  'top_page_views',
]

export const facebookAdsModules: string[] = [
  'facebook_ads_clicks',
  'facebook_ads_impressions',
  'facebook_ads_reach',
  'facebook_ads_cpc',
  'facebook_ads_leads',
  'facebook_ads_spend',
  'facebook_ads_click_through_rate',
  'facebook_ads_cost_per_lead',
]
// Module Components
export const moduleComponents: ModuleComponents = {
  daily_leads_count: {
    visibleLevels: ['Dealership', 'Manufacturer', 'Website'],
    component: GenericModuleComponent,
    title: 'Total Leads',
    infoDescription: 'Total leads received.',
    xLabel: 'Day/Month',
    yLabel: 'Total Leads',
    showSpamLeadsDisclaimer: true,
    group: 'Leads',
  },
  lead_analytics: {
    visibleLevels: ['Dealership', 'Website'],
    component: LeadAnalytics,
    title: 'Lead Analytics',
    infoDescription:
      'An overview of the leads as a whole, including enquiry type, source, total leads etc. as well as change from previous time range',
    showTimeFrame: false,
    button: {
      text: 'View Leads',
      link: `/dealerships/${getDealership()?.slug}/lead_clusters`,
    },
    isCustomChart: true,
    group: 'Leads',
  },
  daily_phone_calls_count: {
    visibleLevels: ['Dealership', 'Website'],
    component: GenericModuleComponent,
    title: 'Daily Phone Calls',
    infoDescription: 'Total phone calls received.',
    xLabel: 'Day/Month',
    yLabel: 'Total Phone Calls',
    group: 'General',
  },
  leads_per_website: {
    visibleLevels: ['Dealership', 'Manufacturer'],
    component: GenericModuleComponent,
    title: 'Leads Per Website',
    infoDescription: 'Total leads received. Sorted by website',
    defaultChartType: 'Pie',
    excludedChartTypes: ['Line'],
    xLabel: 'Website',
    yLabel: 'Leads',
    showSpamLeadsDisclaimer: true,
    group: 'Leads',
  },
  car_stats: {
    visibleLevels: ['Dealership'],
    component: CarStats,
    title: 'Car Stats',
    infoDescription:
      'An overview of the cars as a whole, including cars in stock, recently added/sold cars, average age of stock as well as which cars don’t have photos or dealer comments (CTA’s). Shows a list of the most popular cars by number of views',
    showTimeFrame: false,
    button: {
      text: 'View Cars',
      link: `/dealerships/${getDealership()?.slug}/cars`,
    },
    isCustomChart: true,
    group: 'Cars',
  },
  leads_per_category: {
    visibleLevels: ['Dealership', 'Manufacturer', 'Website'],
    component: GenericModuleComponent,
    title: 'Leads Per Category',
    infoDescription: 'Total leads received. Sorted by category/enquiry type',
    defaultChartType: 'Pie',
    excludedChartTypes: ['Line'],
    xLabel: 'Category',
    yLabel: 'Leads',
    showSpamLeadsDisclaimer: true,
    group: 'Leads',
  },
  delivery_leaderboard: {
    visibleLevels: ['Dealership'],
    component: LeaderBoard,
    title: 'Delivery Leaderboard',
    infoDescription: 'Delivery Leaderboard',
    showTimeFrame: false,
    Icon: Trophy,
    isCustomChart: true,
    group: 'General',
  },
  daily_payments_count: {
    visibleLevels: ['Dealership'],
    component: GenericModuleComponent,
    infoDescription:
      'Shows the total payments received as well as total payment count and total dollar amount.',
    title: 'Payments',
    xLabel: 'Day/Month',
    yLabel: 'Total Payments',
    group: 'General',
  },
  daily_test_drives_count: {
    visibleLevels: ['Dealership'],
    component: GenericModuleComponent,
    infoDescription:
      'Shows the number of test drives.',
    title: 'Test Drives',
    xLabel: 'Day/Month',
    yLabel: 'Total Test Drives',
    group: 'General',
  },
  cars_sold_count: {
    visibleLevels: ['Dealership'],
    component: GenericModuleComponent,
    infoDescription:
      'Shows the total number of cars sold.',
    title: 'Cars Sold',
    xLabel: 'Day/Month',
    yLabel: 'Cars Sold',
    group: 'Cars',
  },
  google_reviews_count: {
    visibleLevels: ['Dealership'],
    component: GenericModuleComponent,
    title: 'Google Reviews',
    infoDescription: 'Total Google reviews received.',
    xLabel: 'Day/Month',
    yLabel: 'Total Reviews',
    group: 'General',
  },
  leads_by_hour: {
    visibleLevels: ['Dealership', 'Website'],
    component: GenericModuleComponent,
    title: 'Leads By Hour',
    infoDescription: 'Shows what time (hour) leads were created.',
    xLabel: 'Hour',
    yLabel: 'Leads',
    showSpamLeadsDisclaimer: true,
    group: 'Leads',
  },
  leads_by_make: {
    visibleLevels: ['Dealership', 'Manufacturer', 'Website'],
    component: LeadsByMake,
    title: 'Leads By Make',
    infoDescription: 'Shows what car make leads were created for.',
    isCustomChart: true,
    group: 'Leads',
  },
  lead_cluster_conversion_rate_by_source: {
    visibleLevels: ['Dealership'],
    excludedChartTypes: ['Line'],
    component: GenericModuleComponent,
    title: 'Lead Conversion Rate By Source',
    infoDescription: 'Shows the conversion rate (as a percentage) of leads by source.',
    xLabel: 'Source',
    yLabel: 'Percentage',
    barChartOptionsConfig: {
      scales: {
        y: {
          title: {
            text: 'Percentage',
          },
          min: 0,
          max: 100,
          ticks: {
            stepSize: 10,
          },
        },
      },
    },
    showSpamLeadsDisclaimer: true,
    isConversionRateModule: true,
    showExcludedCategoriesDisclaimer: true,
    group: 'Leads',
  },
  google_analytics: {
    visibleLevels: ['Dealership', 'Website'],
    component: GenericModuleComponent,
    title: 'Active Users',
    infoDescription: 'Number of unique users who interacted with your site in real time.',
    xLabel: 'Day/Month',
    yLabel: 'Active Users',
    excludedChartTypes: ['Pie'],
    group: 'Google Analytics',
  },
  sessions: {
    visibleLevels: ['Dealership', 'Website'],
    component: GenericModuleComponent,
    title: 'Sessions',
    infoDescription: 'Periods of active user engagement with your site',
    xLabel: 'Day/Month',
    yLabel: 'Total Sessions',
    excludedChartTypes: ['Pie'],
    group: 'Google Analytics',
  },
  sessions_by_medium: {
    visibleLevels: ['Dealership', 'Website'],
    component: GenericModuleComponent,
    title: 'Sessions By Medium',
    infoDescription: 'Shows the sessions breakdown by medium/source.',
    defaultChartType: 'Pie',
    excludedChartTypes: ['Line'],
    xLabel: 'Medium/Source',
    yLabel: 'Sessions',
    group: 'Google Analytics',
  },
  total_users: {
    visibleLevels: ['Dealership', 'Website'],
    component: GenericModuleComponent,
    title: 'Total Users',
    infoDescription: 'Cumulative unique users who have engaged with your site.',
    xLabel: 'Day/Month',
    yLabel: 'Total Users',
    excludedChartTypes: ['Pie'],
    group: 'Google Analytics',
  },
  traffic_by_device: {
    visibleLevels: ['Dealership', 'Website'],
    component: GenericModuleComponent,
    title: 'Traffic By Device',
    infoDescription: 'Shows the Traffic breakdown by Device based on total sessions.',
    defaultChartType: 'Pie',
    excludedChartTypes: ['Line'],
    xLabel: 'Device',
    yLabel: 'Sessions',
    group: 'Google Analytics',
  },
  new_users: {
    visibleLevels: ['Dealership', 'Website'],
    component: GenericModuleComponent,
    title: 'New Users',
    infoDescription: 'First-time visitors to your site.',
    xLabel: 'Day/Month',
    yLabel: 'New Users',
    excludedChartTypes: ['Pie'],
    group: 'Google Analytics',
  },
  views: {
    visibleLevels: ['Dealership', 'Website'],
    component: GenericModuleComponent,
    title: 'Views',
    infoDescription: 'Total number of pages viewed on your site or app, including repeated views.',
    xLabel: 'Day/Month',
    yLabel: 'Total Views',
    excludedChartTypes: ['Pie'],
    group: 'Google Analytics',
  },
  top_page_views: {
    visibleLevels: ['Dealership', 'Website'],
    component: TopPageViews,
    title: 'Top Page Views',
    infoDescription:
      'An overview of the top pages viewed on websites, including page path, total count etc. as well as change from previous time range',
    showTimeFrame: false,
    isCustomChart: true,
    group: 'Google Analytics',
  },
  // Maybe not necessary
  // total_vehicle_leads: {
  //   visibleLevels: ['Dealership', 'Website'],
  //   component: GenericModuleComponent,
  //   title: 'Total Vehicle Leads',
  //   infoDescription: 'Total vehicle related leads received.',
  //   xLabel: 'Day/Month',
  //   yLabel: 'Total Vehicle Leads',
  //   showSpamLeadsDisclaimer: true,
  // },
  total_general_enquiry_leads: {
    visibleLevels: ['Dealership', 'Website'],
    component: GenericModuleComponent,
    title: 'Total General Enquiries',
    infoDescription: 'Total general enquiry leads received.',
    xLabel: 'Day/Month',
    yLabel: 'Total General Enquiries',
    showSpamLeadsDisclaimer: true,
    group: 'Leads',
  },
  total_sales_leads: {
    visibleLevels: ['Dealership', 'Website'],
    component: GenericModuleComponent,
    title: 'Total Sales Leads',
    infoDescription: 'Total sales related leads received.',
    xLabel: 'Day/Month',
    yLabel: 'Total Sales Leads',
    showSpamLeadsDisclaimer: true,
    group: 'Leads',
  },
  total_service_leads: {
    visibleLevels: ['Dealership', 'Website'],
    component: GenericModuleComponent,
    title: 'Total Service Leads',
    infoDescription: 'Total service related leads received.',
    xLabel: 'Day/Month',
    yLabel: 'Total Service Leads',
    showSpamLeadsDisclaimer: true,
    group: 'Leads',
  },
  total_trade_leads: {
    visibleLevels: ['Dealership', 'Website'],
    component: GenericModuleComponent,
    title: 'Total Trade Leads',
    infoDescription: 'Total trade related leads received.',
    xLabel: 'Day/Month',
    yLabel: 'Total Trade Leads',
    showSpamLeadsDisclaimer: true,
    group: 'Leads',
  },
  lead_cluster_conversion_rate_by_location: {
    visibleLevels: ['Dealership'],
    excludedChartTypes: ['Line'],
    component: GenericModuleComponent,
    title: 'Lead Conversion Rate By Location',
    infoDescription: 'Shows the conversion rate (as a percentage) of leads by location.',
    xLabel: 'Location',
    yLabel: 'Percentage',
    barChartOptionsConfig: {
      scales: {
        y: {
          title: {
            text: 'Percentage',
          },
          min: 0,
          max: 100,
          ticks: {
            stepSize: 10,
          },
        },
      },
    },
    showSpamLeadsDisclaimer: true,
    isConversionRateModule: true,
    showExcludedCategoriesDisclaimer: true,
    group: 'Leads',
  },
  lead_cluster_conversion_rate_by_user: {
    visibleLevels: ['Dealership'],
    excludedChartTypes: ['Line'],
    component: GenericModuleComponent,
    title: 'Lead Conversion Rate By Team Member',
    infoDescription: 'Shows the conversion rate (as a percentage) of leads by user.',
    xLabel: 'Team Member',
    yLabel: 'Percentage',
    barChartOptionsConfig: {
      scales: {
        y: {
          title: {
            text: 'Percentage',
          },
          min: 0,
          max: 100,
          ticks: {
            stepSize: 10,
          },
        },
      },
    },
    showSpamLeadsDisclaimer: true,
    isConversionRateModule: true,
    showExcludedCategoriesDisclaimer: true,
    group: 'Leads',
  },
  lead_cluster_conversion_rate_by_source_and_body: {
    visibleLevels: ['Dealership'],
    excludedChartTypes: ['Line', 'Pie'],
    component: GenericModuleComponent,
    title: 'Lead Conversion Rate By Source & Body',
    infoDescription:
      'Shows the conversion rate (as a percentage) of leads by source, with a breakdown per body.',
    xLabel: 'Source',
    yLabel: 'Percentage',
    barChartOptionsConfig: {
      scales: {
        y: {
          min: 0,
          max: 100,
          ticks: {
            stepSize: 10,
          },
        },
      },
    },
    showSpamLeadsDisclaimer: true,
    isConversionRateModule: true,
    showExcludedCategoriesDisclaimer: true,
    group: 'Leads',
  },
  lead_cluster_conversion_by_source: {
    visibleLevels: ['Dealership'],
    excludedChartTypes: ['Line', 'Pie'],
    component: GenericModuleComponent,
    title: 'Lead Conversions By Source',
    infoDescription: 'Shows the total converted leads by source.',
    xLabel: 'Source',
    yLabel: 'Total Leads',
    showSpamLeadsDisclaimer: true,
    showExcludedCategoriesDisclaimer: true,
    group: 'Leads',
  },
  lead_cluster_conversion_by_marketing_source: {
    visibleLevels: ['Dealership'],
    excludedChartTypes: ['Line', 'Pie'],
    component: GenericModuleComponent,
    title: 'Lead Conversions By Marketing Source',
    infoDescription: 'Shows the total converted leads by marketing source.',
    xLabel: 'Marketing Source',
    yLabel: 'Total Leads',
    showSpamLeadsDisclaimer: true,
    showExcludedCategoriesDisclaimer: true,
    group: 'Leads',
  },
  autobot_leads: {
    visibleLevels: ['Website'],
    component: LeadAnalytics,
    title: 'Autobot Leads',
    infoDescription:
      'An overview of the leads from the Chatbot source. Gives more details including enquiry type, source, total leads etc., as well as changes from previous time range',
    showTimeFrame: false,
    button: {
      text: 'View Leads',
      link: `/dealerships/${getDealership()?.slug}/lead_clusters`,
    },
    isCustomChart: true,
    group: 'Leads',
  },
  facebook_ads_engagement_by_campaign: {
    visibleLevels: ['Website'],
    component: EngagementByCampaign,
    title: 'Engagement By Facebook Ads Campaign',
    infoDescription:
      'An comparison between active campaigns, showing metrics such as impressions, reach, spend & clicks.',
    showTimeFrame: false,
    isCustomChart: true,
    group: 'Facebook Ads',
  },
  facebook_ads_clicks: {
    visibleLevels: ['Website'],
    component: GenericModuleComponent,
    excludedChartTypes: ['Pie'],
    title: 'Facebook Ads Clicks',
    infoDescription: 'Total clicks on your Facebook Ads',
    xLabel: 'Day/Month',
    yLabel: 'Total Clicks',
    group: 'Facebook Ads',
  },
  facebook_ads_impressions: {
    visibleLevels: ['Website'],
    component: GenericModuleComponent,
    excludedChartTypes: ['Pie'],
    title: 'Facebook Ads Impressions',
    infoDescription: 'Total impressions on your Facebook Ads',
    xLabel: 'Day/Month',
    yLabel: 'Total Impressions',
    group: 'Facebook Ads',
  },
  facebook_ads_reach: {
    visibleLevels: ['Website'],
    component: GenericModuleComponent,
    excludedChartTypes: ['Pie'],
    title: 'Facebook Ads Reach',
    infoDescription: 'Total reach on your Facebook Ads',
    xLabel: 'Day/Month',
    yLabel: 'Total Reach',
    group: 'Facebook Ads',
  },
  facebook_ads_cpc: {
    visibleLevels: ['Website'],
    component: GenericModuleComponent,
    excludedChartTypes: ['Pie'],
    title: 'Facebook Ads Cost Per Click',
    infoDescription: 'Cost Per Click on your Facebook Ads',
    xLabel: 'Day/Month',
    yLabel: 'Cost Per Click',
    isCurrency: true,
    group: 'Facebook Ads',
  },
  facebook_ads_leads: {
    visibleLevels: ['Website'],
    component: GenericModuleComponent,
    excludedChartTypes: ['Pie'],
    title: 'Facebook Ads Leads',
    infoDescription: 'Total Leads on your Facebook Ads',
    xLabel: 'Day/Month',
    yLabel: 'Total Leads',
    group: 'Facebook Ads',
  },
  facebook_ads_spend: {
    visibleLevels: ['Website'],
    component: GenericModuleComponent,
    excludedChartTypes: ['Pie'],
    title: 'Facebook Ads Spend',
    infoDescription: 'Total spend on your Facebook Ads',
    xLabel: 'Day/Month',
    yLabel: 'Total Spend',
    isCurrency: true,
    group: 'Facebook Ads',
  },
  facebook_ads_click_through_rate: {
    visibleLevels: ['Website'],
    component: GenericModuleComponent,
    excludedChartTypes: ['Pie'],
    title: 'Facebook Ads Click Through Rate',
    infoDescription: 'Click Through Rate (Clicks per Impressions * 100) on your Facebook Ads',
    xLabel: 'Day/Month',
    yLabel: 'Click Through Rate',
    isPercentage: true,
    group: 'Facebook Ads',
  },
  facebook_ads_cost_per_lead: {
    visibleLevels: ['Website'],
    component: GenericModuleComponent,
    excludedChartTypes: ['Pie'],
    title: 'Facebook Ads Cost Per Lead',
    infoDescription: 'Cost Per Lead on your Facebook Ads',
    xLabel: 'Day/Month',
    yLabel: 'Cost Per Lead',
    isCurrency: true,
    group: 'Facebook Ads',
  },
}

export function getModuleComponentFromName(name: string): ModuleComponentConfig {
  return moduleComponents[name]
}

export function getDealershipFromWindow(): Dealership {
  if (typeof window !== 'undefined') {
    return (window as any).dealership
  }
  return null
}

export function getDealership(): Dealership {
  const rootElement = document.getElementById('app_root')
  const elementAttribute = rootElement?.getAttribute('dealership')
  const dealership = elementAttribute ? JSON.parse(elementAttribute) : getDealershipFromWindow()
  return dealership
}
export function getManufacturer(): Manufacturer {
  const rootElement = document.getElementById('app_root')
  const elementAttribute = rootElement?.getAttribute('manufacturer')
  const manufacturer = JSON.parse(elementAttribute)
  return manufacturer
}
export function getWebsite(): Website {
  const rootElement = document.getElementById('app_root')
  const elementAttribute = rootElement?.getAttribute('website')
  const website = JSON.parse(elementAttribute)
  return website
}

export function getAnalyticsBlock(): AnalyticsBlock {
  const rootElement = document.getElementById('app_root')
  const analyticsBlock = JSON.parse(rootElement?.getAttribute('analytics_block'))
  return analyticsBlock
}

export function getDealershipName(): string {
  const dealership = getDealership()
  return dealership?.name || slugToTitle(dealership?.slug)
}

export function getNameBasedOnLevel(level: Level): string | undefined {
  if (level === 'Dealership') {
    return getDealershipName()
  } else if (level === 'Website') {
    return slugToTitle(getWebsite()?.slug)
  } else {
    return undefined
  }
}

export function getUserId(): number {
  const rootElement = document.getElementById('app')
  const userId = rootElement.getAttribute('userId')
  return Number(userId)
}
export async function fetchModuleData({
  module,
  timeRange,
  selectedLocation,
  category,
}: {
  module: string
  level: Level
  timeRange?: TimeRange
  selectedLocation?: Location
  category?: string
}): Promise<any> {
  const URLParams = {
    module: module,
    start: timeRange?.start,
    end: timeRange?.end,
    timeframe: timeRange?.timeframe,
    location_id: selectedLocation?.id === -1 ? null : selectedLocation?.id,
    category: category || undefined,
  }

  const dealership = getDealership()
  const manufacturer = getManufacturer()
  const website = getWebsite()

  const dealershipURL = dealership
    ? Routes.dealership_analytics_blocks_path(dealership.slug, URLParams)
    : undefined

  const manufacturerURL = manufacturer
    ? Routes.manufacturer_analytics_blocks_path(manufacturer.slug, URLParams)
    : undefined

  const websiteURL = website
    ? Routes.website_analytics_blocks_path(website.slug, URLParams)
    : undefined

  const URL = manufacturerURL || websiteURL || dealershipURL

  const response = await fetch(URL)

  if (!response.ok) {
    throw new Error('Error fetching data')
  }

  // console.log(await response.json()) // For debugging

  return await response.json()
}

// Save the module layout
export async function setModuleLayouts(
  tabLayouts: { [key: number]: ModuleItem[] },
  tabTitles: string[]
): Promise<void> {
  const analyticsBlock = getAnalyticsBlock()

  const tabData = Object.values(tabLayouts).map((layout, index) => {
    // For testing purposes
    const modifiedLayout = layout?.map((item, itemIndex) => {
      // Change to true to test
      if (false) {
        return {
          ...item,
          id: `${itemIndex}`,
        }
      }
      return item
    })

    return {
      id: index,
      title: tabTitles[index] || `Tab ${index + 1}`,
      layout: modifiedLayout,
    }
  })

  analyticsBlock.data = {
    tabs: tabData,
  }

  const dealership = getDealership()
  const manufacturer = getManufacturer()
  const website = getWebsite()

  const dealershipURL = dealership
    ? Routes.update_block_dealership_analytics_blocks_path(dealership.slug)
    : undefined

  const manufacturerURL = manufacturer
    ? Routes.update_block_manufacturer_analytics_blocks_path(manufacturer.slug)
    : undefined

  const websiteURL = website
    ? Routes.update_block_website_analytics_blocks_path(website.slug)
    : undefined

  const URL: string = manufacturerURL || websiteURL || dealershipURL

  try {
    const response = await fetch(URL, {
      method: 'PUT',
      headers: standardHeaders,
      body: JSON.stringify({ analytics_block: analyticsBlock }),
    })

    if (!response.ok) {
      throw new Error('Error saving order')
    }
    const rootElement = document.getElementById('app_root')
    rootElement.setAttribute('analytics_block', JSON.stringify(analyticsBlock))
  } catch (error) {
    console.error(error)
  }
}

// Fetch the module layout
export function fetchModuleLayoutByTab(tabIndex: number): ModuleItem[] {
  const analyticsBlock = getAnalyticsBlock()
  return analyticsBlock?.data?.tabs?.find((tab) => tab.id === tabIndex)?.layout
}

// Get tabs
export function getTabTitles(): string[] {
  const analyticsBlock = getAnalyticsBlock()
  const tabTitles = analyticsBlock?.data?.tabs?.map((tab) => tab.title)
  return tabTitles?.length ? tabTitles : ['Tab 1']
}

// Creates an array of ModuleItem's, if the visibleLevels preoperty includes the level
export const getModuleItemsBasedOnLevel = (level: Level): ModuleItem[] => {
  if (level === 'Website') {
    const website = getWebsite()
    // Create an array of excluded modules
    let excludedModules: string[] = []

    // If the website doesn't have a GA profile, exclude the GA4 modules
    if (!website?.gaProfileExists) {
      excludedModules = excludedModules.concat(ga4Modules)
    }

    // If the website doesn't have a Facebook account, exclude the Facebook Ads modules
    // Might need to adjust when we bring in google ads campaigns
    if (!website?.hasCampaigns) {
      excludedModules = excludedModules.concat(facebookAdsModules)
    }

    return Object.keys(moduleComponents)
      .filter((key) => !excludedModules.includes(key))
      .reduce((acc, key) => {
        if (moduleComponents[key].visibleLevels.includes(level)) {
          acc.push({ module: key })
        }
        return acc
      }, [])
  }

  return Object.keys(moduleComponents).reduce((acc, key) => {
    if (moduleComponents[key].visibleLevels.includes(level)) {
      acc.push({ module: key })
    }
    return acc
  }, [])
}

export const hasMultipleDataSets = (chartData: ChartData): boolean | undefined => {
  // If there's one dataset it will be an array:
  if (Array.isArray(chartData)) {
    return false
  }

  // If there's multiple datasets it will be an object:
  if (typeof chartData === 'object' && chartData !== null) {
    // Check that each of the object values is an array
    const values = Object.values(chartData)
    if (values.length > 0) {
      return values.every((value) => Array.isArray(value))
    }
  }

  // return undefined for unknown data
  return undefined
}

// convert leads_chart to Leads Chart for example
export function snakeCaseToTitleCase(module: string): string {
  return module
    .split('_')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ')
}

// convert orange-motor-group to Orange Motor Group for example
export function slugToTitle(module: string): string {
  return module
    .split('-')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ')
}

// Merges objects based on lodash merging
export function mergeDeep(...objects: Array<object>): object {
  const isObject = (obj: any) =>
    obj !== null && obj !== undefined && typeof obj === 'object' && !Array.isArray(obj)

  return objects.filter(isObject).reduce((prev, obj) => {
    Object.keys(obj).forEach((key) => {
      const pVal = prev[key]
      const oVal = obj[key]

      if (isObject(pVal) && isObject(oVal)) {
        prev[key] = mergeDeep(pVal, oVal)
      } else {
        prev[key] = oVal
      }
    })

    return prev
  }, {})
}

export const handleNotificationEvent = (
  notification: RefObject<Toast>,
  message: string,
  type: NotificationType
): void => {
  showToast(notification, type, capitalize(type.toString()), message)
}

export function linkFacebookAccount(dealershipSlug: string | undefined): void {
  const apiUrl = 'https://www.facebook.com/v5.0/dialog/oauth'
  const params = new URLSearchParams({
    client_id: '305713216982753',
    redirect_uri: `${rootUrl}/facebook/oauth`,
    scope: 'ads_read,read_insights',
    state: `asset_type=facebook_ad_account&dealership_slug=${dealershipSlug}`,
  })

  window.open(`${apiUrl}?${params.toString()}`, '_blank')
}
