import { useEffect, useRef, useState } from 'react'
import { ModuleItem } from './types'
import { Toast } from 'primereact/toast'
import { getDealership, setModuleLayouts } from './utils'
import showToast from '../shared/ShowToast'
import { Save, Exit, Reset } from './Icons'
import * as Routes from '../../routes'

type EditModeProps = {
  editModeEnabled: boolean
  toggleEditMode: () => void
  handleSetPreviousItems: () => void
  handleSetDefaultItems: () => void
  addGAProfile: () => void
  tabLayouts: { [key: number]: ModuleItem[] }
  tabTitles: string[]
  level: string
  gaProfileExists: boolean
  hasCampaigns: boolean
}

const EditMode: React.FC<EditModeProps> = ({
  editModeEnabled,
  toggleEditMode,
  handleSetPreviousItems,
  handleSetDefaultItems,
  addGAProfile,
  tabLayouts,
  tabTitles,
  level,
  gaProfileExists,
  hasCampaigns,
}) => {
  const [sidebarWidth, setSidebarWidth] = useState(0)
  const dealership = getDealership()

  function handleSaveLayout(): void {
    toggleEditMode()
    setModuleLayouts(tabLayouts, tabTitles)
    handleNotificationEvent('save')
  }

  function handleExitWithoutSaving(): void {
    toggleEditMode()
    handleSetPreviousItems()
    handleNotificationEvent('exit')
  }

  function handleResetToDefault(): void {
    toggleEditMode()
    handleSetDefaultItems()
    handleNotificationEvent('default')
  }

  // Dynamically gets the sidebar width, so the edit mode buttons can be centered
  const sidebar = document.getElementById('sidebar')
  useEffect(() => {
    if (sidebar) {
      const handleResize = () => {
        setSidebarWidth(sidebar.offsetWidth)
      }

      const resizeObserver = new ResizeObserver(handleResize)
      resizeObserver.observe(sidebar)
      setSidebarWidth(sidebar.offsetWidth)

      return () => {
        resizeObserver.unobserve(sidebar)
      }
    }
  }, [sidebar])

  const notification = useRef(null)

  const handleNotificationEvent = (type: string) => {
    if (type === 'save') {
      showToast(notification, 'success', 'Layout Saved', 'Your layout has been saved successfully')
    } else if (type === 'exit') {
      showToast(
        notification,
        'info',
        'Layout Reset',
        'Your layout has been reset to the previous layout'
      )
    } else if (type === 'default') {
      showToast(
        notification,
        'info',
        'Default Layout',
        'Your layout has been reset to the default layout'
      )
    }
  }

  return (
    <>
      <Toast ref={notification} />
      {level == 'Website' && !gaProfileExists ? (
        <div className="btn px-4 btn-primary ml-auto mr-2 d-block" onClick={addGAProfile}>
          <i className="fas fa-plus mr-2"></i>Add GA Profile
        </div>
      ) : null}
      {level === 'Website' && !hasCampaigns ? (
        dealership ? (
          <a
            href={Routes.dealership_social_ad_accounts_path(dealership)}
            className="text-decoration-none"
          >
            <div className="btn px-4 btn-primary ml-auto mr-2 d-block">
              <i className="fas fa-plus mr-2"></i>Add FB Ads Profile
            </div>
          </a>
        ) : null
      ) : null}
      <div
        className={'btn px-4 btn-primary' + (editModeEnabled ? ' d-none' : ' d-block')}
        onClick={toggleEditMode}
      >
        <i className="fas fa-edit mr-2"></i>Enter Edit Mode
      </div>
      <div>
        <div
          className={editModeEnabled ? ' d-flex position-fixed' : ' d-none'}
          style={
            editModeEnabled
              ? {
                  zIndex: '100',
                  width: `calc(100% - ${sidebarWidth}px)`,
                  left: `${sidebarWidth}px`,
                  height: '90px',
                  bottom: '0%',
                  marginBottom: `20px`,
                }
              : null
          }
        >
          <div
            style={
              editModeEnabled
                ? {
                    display: 'flex',
                    justifyContent: 'center',
                    height: '100%',
                    margin: 'auto',
                    backgroundColor: 'rgba(245, 247, 251, 0.85)',
                    boxShadow: '0 0 20px 0 rgba(0, 0, 0, 0.2)',
                    borderRadius: '10px',
                  }
                : null
            }
            className="edit-mode-bar d-flex"
          >
            <button className={'btn btn-success edit-btn'} onClick={handleSaveLayout}>
              Save Layout <Save />
            </button>
            <button className={'btn btn-danger edit-btn'} onClick={handleExitWithoutSaving}>
              Exit Without Saving <Exit />
            </button>
            {/* TODO: temporarily disabled */}
            {/* <button className={'btn btn-primary edit-btn'} onClick={handleResetToDefault}>
              Reset to Default <Reset />
            </button> */}
          </div>
        </div>
      </div>
    </>
  )
}

export default EditMode
