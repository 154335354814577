import flatpickr from 'flatpickr'
import moment from 'moment'
import { useCallback, useEffect, useState } from 'react'

const dropdownOptions = [
  {
    label: 'Last 7 days',
    start: moment().subtract(7, 'd').format('DD-MM-YY'),
    end: moment().subtract(1, 'd').format('DD-MM-YY'),
    type: 'weekly',
  },
  {
    label: 'Last 30 days',
    start: moment().subtract(30, 'd').format('DD-MM-YY'),
    end: moment().subtract(1, 'd').format('DD-MM-YY'),
    type: 'monthly',
  },
  {
    label: moment().subtract(1, 'months').format('MMMM'),
    start: moment().subtract(1, 'months').startOf('month').format('DD-MM-YY'),
    end: moment().subtract(1, 'months').endOf('month').format('DD-MM-YY'),
    type: 'monthly',
  },
  {
    label: moment().subtract(2, 'months').format('MMMM'),
    start: moment().subtract(2, 'months').startOf('month').format('DD-MM-YY'),
    end: moment().subtract(2, 'months').endOf('month').format('DD-MM-YY'),
    type: 'monthly',
  },
  {
    label: moment().subtract(3, 'months').format('MMMM'),
    start: moment().subtract(3, 'months').startOf('month').format('DD-MM-YY'),
    end: moment().subtract(3, 'months').endOf('month').format('DD-MM-YY'),
    type: 'monthly',
  },
  {
    label: 'Last 364 days',
    start: moment().subtract(1, 'years').format('DD-MM-YY'),
    end: moment().subtract(1, 'd').format('DD-MM-YY'),
    type: 'yearly',
  },
]

const TimeRangePickerComponent = ({ handleTimeRangeChange }) => {
  const [minDate, setMinDate] = useState(null)
  const [maxDate, setMaxDate] = useState(null)
  const [dateStr, setDateStr] = useState(null)
  const [timeRangeType, setTimeRangeType] = useState(null)

  const handleDropdownClick = (item) => {
    const pickr = document.getElementById('flatpickr-date-range')._flatpickr
    pickr.setDate([item.start, item.end], true)
    setTimeRangeType(item.type)
  }

  const handleDatePickerChange = useCallback((selectedDates, dateStr) => {
    let start = Date.parse(selectedDates[0]) / 1000
    let end = Date.parse(selectedDates[1]) / 1000 + 60 * 60 * 24 + 30000
    if (start && end) {
      setMinDate(start)
      setMaxDate(end)
      setDateStr(`from ${dateStr}`)
    } else {
      handleTimeRangeChange({
        start: minDate,
        end: maxDate,
        timeframe: dateStr,
        type: timeRangeType,
      })
    }
  }, [])

  useEffect(() => {
    if (minDate && maxDate) {
      handleTimeRangeChange({
        start: minDate,
        end: maxDate,
        timeframe: dateStr,
        type: timeRangeType,
      })
    }
  }, [minDate, maxDate])

  useEffect(() => {
    flatpickr('#flatpickr-date-range', {
      allowInput: true,
      dateFormat: 'd/m/y',
      maxDate: moment().format('DD/MM/YY'),
      mode: 'range',
      onChange: (selectedDates, dateStr, instance) => {
        handleDatePickerChange(selectedDates, dateStr)
      },
    })
  }, [])

  return (
    <div>
      <div className="input-group">
        <input
          type="text"
          className="form-control"
          id="flatpickr-date-range"
          placeholder="Time Range..."
        />
        <div className="input-group-append">
          <button
            type="button"
            className="btn btn-outline-secondary dropdown-toggle"
            data-toggle="dropdown"
            aria-expanded="false"
            aria-haspopup="true"
          />
          <div className="dropdown-menu dropdown-menu-right">
            {dropdownOptions.map((item) => (
              <a
                key={item.label}
                className="dropdown-item"
                onClick={() => handleDropdownClick(item)}
              >
                {item.label}
              </a>
            ))}
          </div>
        </div>
      </div>
    </div>
  )
}

const TimeRangePicker = connectRange(TimeRangePickerComponent)
export default TimeRangePicker

// TODO (Codemod generated): ensure your usage correctly maps the props from the connector to the hook
function connectRange(Component) {
  const Range = (props) => {
    return <Component {...props} />
  }

  return Range
}
