import { CSSProperties } from 'react'
import ModulePicker from './ModulePicker'
import { ModuleItem } from './types'

const BlankModule: React.FC<{ editModeEnabled: boolean; items: ModuleItem[] }> = ({
  editModeEnabled,
  items,
}) => {
  const styles: CSSProperties = {
    display: editModeEnabled ? 'flex' : 'none',
    width: '100%',
    borderRadius: '10px',
    backgroundColor: '#fff',
    height: '25vw',
    border: '3px solid #cdd4da',
    borderStyle: 'dashed',
    justifyContent: 'center',
    alignItems: 'center',
  }
  return (
    <div style={styles}>
      <ModulePicker items={items} hideSelect isBlankModule />
    </div>
  )
}

export default BlankModule
