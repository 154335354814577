import { useState, useEffect, useCallback, useMemo } from 'react'
import * as Routes from '../../routes'
import { getDealership } from './utils'
import { Location } from './types'

type SelectLocationsProps = {
  handleLocationChange: (arg0: Location) => void
}

const SelectLocations: React.FC<SelectLocationsProps> = ({ handleLocationChange }) => {
  const [loading, setLoading] = useState<boolean>(false)
  const [locations, setLocations] = useState<Location[]>([])
  const [selectedLocation, setSelectedLocation] = useState<Location>({ id: 0, name: '' })
  const dealership = getDealership()

  const allLocations: Location = { id: -1, name: 'All Locations' }

  const fetchLocations = useCallback(async (): Promise<void> => {
    if (dealership?.slug) {
      setLoading(true)
      const response = await fetch(
        `${Routes.dealership_locations_path(dealership?.slug)}?with_lead=true`,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
          },
        }
      )
      const locationData: Location[] = await response.json()
      setLoading(false)
      if (locationData) {
        setLocations([allLocations, ...locationData])
      }
    }
  }, [dealership?.slug])

  useEffect(() => {
    fetchLocations()
  }, [fetchLocations])

  const memoizedLocations = useMemo(() => {
    return locations
  }, [locations])

  const onLocationChange = useCallback(
    (event: { target: { value: string } }): void => {
      const newSelectedLocation = memoizedLocations.find(
        (location) => location.name === event.target.value
      )
      if (newSelectedLocation) {
        setSelectedLocation(newSelectedLocation)
        handleLocationChange(newSelectedLocation)
      }
    },
    [memoizedLocations, handleLocationChange]
  )

  return (
    <>
      {!loading && memoizedLocations.length > 0 && (
        <div className="input-form-group">
          <select
            value={selectedLocation.name}
            onChange={onLocationChange}
            className="form-control"
            id="location-select"
          >
            <option value="">Location...</option>
            {memoizedLocations.map((location) => (
              <option key={location.id} value={location.name}>
                {location.name}
              </option>
            ))}
          </select>
        </div>
      )}
    </>
  )
}

export default SelectLocations
